import React, { useEffect, useState, useContext } from "react";
import { Table, Button, Popconfirm, message, Divider, Layout } from "antd";
import axios from "../axiosInstance";
import { useParams } from "react-router-dom"; // Import useParams
import ImageUploader from "./ImageUploader";
import JwtContext from "../JwtContext"; // Import JwtContext
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Content } = Layout;

function VehicleDetail() {
  const { id: vehicleId } = useParams(); // Use useParams to get vehicleId
  const { jwtToken } = useContext(JwtContext); // Use JwtContext for authentication
  const [vehicle, setVehicle] = useState({});
  const [labels, setLabels] = useState([]); // To store labels and associated images

  useEffect(() => {
    if (jwtToken) {
      // Fetch vehicle details
      axios
        .get(`/vehicles/${vehicleId}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${jwtToken}` }, // Pass the JWT token in the request
        })
        .then((res) => {
          setVehicle(res.data);
        })
        .catch((err) => {
          message.error("Error fetching vehicle details");
        });

      // Fetch labels and associated images for this vehicle
      axios
        .get(`/labels`, {
          params: { vehicleId },
          withCredentials: true,
          headers: { Authorization: `Bearer ${jwtToken}` }, // Pass the JWT token in the request
        })
        .then((res) => {
          //sort labels by id
          res.data.sort((a, b) => a.id - b.id);
          setLabels(res.data); // Each label should include its images
        })
        .catch((err) => {
          message.error("Error fetching labels");
        });
    }
  }, [vehicleId, jwtToken]);

  const handleDeleteImage = (imageId, labelId) => {
    axios
      .delete(`/images/${imageId}/${labelId}`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${jwtToken}` }, // Pass the JWT token in the request
      })
      .then(() => {
        // Remove the image from the label
        setLabels((prevLabels) =>
          prevLabels.map((label) =>
            label.id === labelId ? { ...label, Images: [] } : label
          )
        );
        message.success("Image deleted successfully");
      })
      .catch((err) => {
        message.error("Error deleting image");
      });
  };

  const handleImageUploadSuccess = (imageUrl, labelId) => {
    // Update the label with the new image
    setLabels((prevLabels) =>
      prevLabels.map((label) =>
        label.id === labelId
          ? { ...label, Images: [{ path: imageUrl }] }
          : label
      )
    );
  };

  const columns = [
    {
      title: "Label Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      key: "image",
      render: (_, record) =>
        record.Images?.length > 0 ? (
          <a
            href={`https://carmedia.camclarkgroup.com/uploads/${record.Images[0].path}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt={record.name}
              src={`https://carmedia.camclarkgroup.com/uploads/${record.Images[0].path}`}
              style={{ width: "80px" }}
            />
          </a>
        ) : (
          "No Image"
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          {record.Images?.length > 0 ? (
            <Popconfirm
              title="Are you sure you want to delete this image?"
              onConfirm={() =>
                handleDeleteImage(record.Images[0].id, record.id)
              }
              okText="Yes"
              cancelText="No"
            >
              <Button type="default" danger>
                Remove
              </Button>
            </Popconfirm>
          ) : (
            <ImageUploader
              vehicleId={vehicleId}
              labelId={record.id}
              onUploadSuccess={(imageUrl) =>
                handleImageUploadSuccess(imageUrl, record.id)
              }
            />
          )}
        </>
      ),
    },
  ];

  if (!jwtToken) {
    return <p>Please login to view vehicle details.</p>;
  }

  return (
    <Content style={{ padding: "20px" }}>
      <div style={{ display: "flex", justifyContent: "start" }}>
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => window.history.back()}
        >
          Go Back
        </Button>
        <h1>
          {vehicle?.make} {vehicle?.model} {vehicle?.year} Images
        </h1>
      </div>

      <Divider />
      <Table
        dataSource={labels}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
    </Content>
  );
}

export default VehicleDetail;
