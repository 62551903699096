import React, { useState, useContext } from "react";
import { Upload, Button, Progress, Form, message } from "antd";
import { UploadOutlined, CameraOutlined } from "@ant-design/icons";
import axios from "../axiosInstance";
import JwtContext from "../JwtContext"; // Import JwtContext

function ImageUploader({ vehicleId, labelId, onUploadSuccess }) {
  const { jwtToken } = useContext(JwtContext); // Use JwtContext to get jwtToken
  const [uploadProgress, setUploadProgress] = useState(0);
  const [form] = Form.useForm();

  const handleUpload = ({ file }) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("vehicleId", vehicleId);
    formData.append("labelId", labelId); // Use the labelId passed from VehicleDetail

    // Make the upload request with jwtToken in the headers
    axios
      .post("/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwtToken}`, // Include jwtToken
        },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted); // Update progress
        },
      })
      .then((res) => {
        setUploadProgress(0);
        form.resetFields();
        message.success("Image uploaded successfully");
        if (onUploadSuccess) {
          onUploadSuccess(res.data.imageUrl); // Call success callback to refresh the UI
        }
      })
      .catch((err) => {
        setUploadProgress(0);
        message.error("Error uploading image");
        console.error("Error uploading image:", err);
      });
  };

  const handleCapture = (event) => {
    handleUpload({ file: event.target.files[0] });
  };

  return (
    <>
      <Form form={form} layout="inline">
        {/* <Form.Item>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            style={{ display: "none" }}
            id={`captureImage-${labelId}`}
            onChange={handleCapture}
          />
          <label htmlFor={`captureImage-${labelId}`}>
            <Button icon={<CameraOutlined />}>Capture</Button>
          </label>
        </Form.Item> */}
        <Form.Item>
          <Upload customRequest={handleUpload} showUploadList={false}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
      {uploadProgress > 0 && (
        <Progress percent={uploadProgress} style={{ width: "200px" }} />
      )}
    </>
  );
}

export default ImageUploader;
