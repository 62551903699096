// src/components/NavBar.js
import React, { useEffect, useContext } from "react";
import { Layout, Menu, Button } from "antd";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../msalConfig";
import JwtContext from "../JwtContext";

const { Header } = Layout;

function NavBar() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { setJwtToken } = useContext(JwtContext);

  useEffect(() => {
    if (isAuthenticated && accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          console.log("silent token acquisition successful", response);
          // Exchange Azure AD token for backend JWT
          fetch("https://carmedia.camclarkgroup.com/auth/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: response.idToken }),
          })
            .then((res) => res.json())
            .then((data) => {
              console.log("navbar auth login call", data);
              const jwtToken = data.token;
              localStorage.setItem("jwtToken", jwtToken);
              setJwtToken(jwtToken);
            })
            .catch((err) => {
              console.error("Backend login error:", err);
            });
        })
        .catch((err) => {
          console.error("Token acquisition error:", err);
        });
    }
  }, [isAuthenticated, accounts, instance, setJwtToken]);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    setJwtToken(null);
    instance.logoutRedirect();
  };

  const user = isAuthenticated && accounts.length > 0 ? accounts[0] : null;

  return (
    <Header>
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" selectable={false}>
        {isAuthenticated ? (
          <>
            <Menu.Item key="1">Welcome, {user && user.name}</Menu.Item>
            <Menu.Item key="2">
              <Button onClick={handleLogout}>Logout</Button>
            </Menu.Item>
          </>
        ) : (
          <Menu.Item key="1">
            <Button onClick={handleLogin}>Login with Microsoft</Button>
          </Menu.Item>
        )}
      </Menu>
    </Header>
  );
}

export default NavBar;
