// src/App.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { msalConfig } from "./msalConfig";
import NavBar from "./components/NavBar";
import VehicleList from "./components/VehicleList";
import VehicleDetail from "./components/VehicleDetail";
import JwtContext from "./JwtContext";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwtToken"));

  return (
    <MsalProvider instance={msalInstance}>
      <JwtContext.Provider value={{ jwtToken, setJwtToken }}>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<VehicleList />} />
            <Route path="/vehicle/:id" element={<VehicleDetail />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </JwtContext.Provider>
    </MsalProvider>
  );
}

export default App;
