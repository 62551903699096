import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../axiosInstance";
import {
  Table,
  Spin,
  Alert,
  Form,
  Input,
  Button,
  Popconfirm,
  message,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import JwtContext from "../JwtContext";
import axios from "axios";

function VehicleList() {
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]); // State for filtered vehicles
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { jwtToken } = useContext(JwtContext);
  const [searchText, setSearchText] = useState(""); // Search text state

  const [newVehicle, setNewVehicle] = useState({
    make: "",
    model: "",
    year: "",
    stock: "",
    vin: "",
  });
  const [adding, setAdding] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (!jwtToken) {
      setLoading(false);
      return;
    }
    axiosInstance
      .get("/vehicles")
      .then((res) => {
        setVehicles(res.data);
        setFilteredVehicles(res.data); // Set the filtered list initially
        setLoading(false);
      })
      .catch((err) => {
        console.error("API call error:", err);
        setError("Failed to load vehicles.");
        setLoading(false);
      });
  }, [jwtToken]);

  // Add vehicle logic
  const handleAddVehicle = () => {
    const { stock, make, model, year, vin } = newVehicle;
    if (!stock) {
      setError("Stock is required");
      return;
    }
    if (!make) {
      setError("Make is required");
      return;
    }
    if (!model) {
      setError("Model is required");
      return;
    }
    if (!year) {
      setError("Year is required");
      return;
    }
    // if(!vin ) {
    //   setError("VIN is required");
    //   return;
    // }

    setAdding(true);
    axiosInstance
      .post("/vehicles", newVehicle)
      .then((res) => {
        setVehicles([...vehicles, res.data]);
        setFilteredVehicles([...vehicles, res.data]); // Update filtered list
        setNewVehicle({ make: "", model: "", year: "", stock: "", vin: "" });
        setAdding(false);
        setIsModalVisible(false); // Close the modal after adding
      })
      .catch((err) => {
        console.error("Failed to add vehicle:", err);
        setError("Failed to add vehicle.");
        setAdding(false);
      });
  };

  // Delete vehicle logic
  const handleDeleteVehicle = (id) => {
    axiosInstance
      .delete(`/vehicles/${id}`)
      .then(() => {
        const updatedVehicles = vehicles.filter((vehicle) => vehicle.id !== id);
        setVehicles(updatedVehicles);
        setFilteredVehicles(updatedVehicles); // Update filtered list
        message.success("Vehicle deleted successfully");
      })
      .catch((err) => {
        console.error("Failed to delete vehicle:", err);
        setError("Failed to delete vehicle.");
      });
  };

  // Open/Close modal logic
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setNewVehicle({ make: "", model: "", year: "", stock: "", vin: "" }); // Reset form fields
  };

  // Simulate scanning VIN (actual scan functionality would require a package like react-barcode-scanner)
  const handleScanVin = () => {
    const scannedVin = "5NPE34AF7JH697467"; // Simulated scan result
    setNewVehicle({ ...newVehicle, vin: scannedVin });
    fetchVinDetails(scannedVin); // Fetch details once scanned
  };

  // Fetch vehicle details by VIN
  const fetchVinDetails = (vin) => {
    const options = {
      method: "GET",
      url: `https://vin-decoder19.p.rapidapi.com/vin_decoder_basic?vin=${vin}`,
      headers: {
        "x-rapidapi-key": "aa85923474mshec5a41a43cb58adp116d91jsnb2ed73c74a22",
        "x-rapidapi-host": "vin-decoder19.p.rapidapi.com",
      },
    };

    axios
      .request(options)
      .then((response) => {
        const data = response.data;
        console.log("VIN details:", data);
        // Update the form with fetched VIN data
        setNewVehicle((prevVehicle) => ({
          ...prevVehicle,
          make: data.Make.value || "",
          model: data.Model.value || "",
          trim: data.Trim_Level.value || "",
          year: data.Model_Year.value || "",
        }));
      })
      .catch((error) => {
        console.error("Failed to fetch VIN details:", error);
        setError("Failed to fetch VIN details.");
      });
  };

  // VIN input change handler
  const handleVinChange = (e) => {
    const vin = e.target.value;
    setNewVehicle({ ...newVehicle, vin });

    // Call VIN decoder when VIN is fully entered (17 characters for standard VIN)
    if (vin.length === 17) {
      fetchVinDetails(vin);
    }
  };

  // Search handler
  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchText(searchText);

    const filtered = vehicles.filter(
      (vehicle) =>
        vehicle.make.toLowerCase().includes(searchText) ||
        vehicle.model.toLowerCase().includes(searchText) ||
        String(vehicle.year).toLowerCase().includes(searchText) ||
        vehicle.stock.toLowerCase().includes(searchText)
    );
    setFilteredVehicles(filtered);
  };

  // Table columns
  const columns = [
    {
      title: "VIN",
      dataIndex: "vin",
      key: "vin",
      responsive: ["sm"],
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
      responsive: ["sm"],
    },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      responsive: ["sm"],
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      responsive: ["sm"],
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      responsive: ["sm"],
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div>
          <Button type="link">
            <Link to={`/vehicle/${record.id}`}>Images</Link>
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this vehicle?"
            onConfirm={() => handleDeleteVehicle(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  if (loading) {
    return <Spin tip="Loading vehicles..." />;
  }

  // if (error && jwtToken) {
  //   return <Alert message="Error" description={error} type="error" showIcon />;
  // }

  if (!jwtToken) return null;

  return (
    <div style={{ padding: "24px" }}>
      {/* Search Bar */}
      <Input
        placeholder="Search vehicles by make, model, year, or stock"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: "20px" }}
      />

      <Button type="primary" onClick={handleOpenModal}>
        Add Vehicle
      </Button>
      {error && (
        <Alert
          onClick={() => setError(false)}
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      )}
      {/* Modal for adding a new vehicle */}
      <Modal
        title="Add Vehicle"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAddVehicle}
            loading={adding}
          >
            Save
          </Button>,
        ]}
      >
        {error && (
          <Alert
            onClick={() => setError(false)}
            message="Error"
            description={error}
            type="error"
            showIcon
          />
        )}
        <Form layout="vertical">
          <Form.Item label="VIN">
            <Input
              placeholder="VIN"
              value={newVehicle.vin}
              onChange={handleVinChange}
            />
            {/* <Button
              type="primary"
              onClick={handleScanVin}
              style={{ marginTop: "10px" }}
            >
              Scan VIN
            </Button> */}
          </Form.Item>
          <Form.Item label="Stock">
            <Input
              required
              placeholder="Stock"
              value={newVehicle.stock}
              onChange={(e) =>
                setNewVehicle({ ...newVehicle, stock: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Make">
            <Input
              required
              placeholder="Make"
              value={newVehicle.make}
              onChange={(e) =>
                setNewVehicle({ ...newVehicle, make: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Model">
            <Input
              required
              placeholder="Model"
              value={newVehicle.model}
              onChange={(e) =>
                setNewVehicle({ ...newVehicle, model: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Year">
            <Input
              required
              placeholder="Year"
              value={newVehicle.year}
              onChange={(e) =>
                setNewVehicle({ ...newVehicle, year: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Trim">
            <Input
              required
              placeholder="Trim"
              value={newVehicle.trim}
              onChange={(e) =>
                setNewVehicle({ ...newVehicle, trim: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Vehicle Table */}
      <Table
        columns={columns}
        dataSource={filteredVehicles} // Use filtered vehicles for table data
        rowKey="id"
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
}

export default VehicleList;
