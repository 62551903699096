// src/msalConfig.js
export const msalConfig = {
  auth: {
    clientId: "b3d5371f-a369-471e-bd23-ead53f6927e8",
    authority:
      "https://login.microsoftonline.com/53cce716-d0b1-4cef-b9ab-98a57050ba43",
    redirectUri: "https://carmedia.camclarkgroup.com",
  },
  cache: {
    cacheLocation: "sessionStorage", // or 'localStorage'
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "email"], // Scopes required for authentication
};
